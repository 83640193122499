import {
  HOSPITAL_CLEAR,
  HOSPITAL_LISTS,
  HOSPITAL_DATA,
  HOSPITAL_FETCH,
  HOSPITAL_CREATE,
  HOSPITAL_UPDATE
} from '../actions/types';

const initialState = {
  lists: [],
  data: [],
  isAddMore: false,
  nextKey: ''
};

export default function HospitalReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case HOSPITAL_LISTS:
    case HOSPITAL_DATA:
    case HOSPITAL_CREATE:
    case HOSPITAL_UPDATE:
      return { ...state, ...action.playload };
    case HOSPITAL_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case HOSPITAL_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
