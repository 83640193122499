import {
  EMAIL_SEND_OTP,
  EMAIL_VERIFY_OTP,
  EMAIL_CLEAR
} from '../actions/types';

const initialState = {
  ref: null
};

export default function EmailReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case EMAIL_SEND_OTP:
    case EMAIL_VERIFY_OTP:
      return { ...state, ...action.playload };
    case EMAIL_CLEAR:
      return { ...state, status: undefined };
    default:
      return { ...state };
  }
}
