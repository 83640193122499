import {
  TOKEN_CLEAR,
  TOKEN_FETCH,
  TOKEN_FETCH_ONE
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  nextKey: ''
};

export default function TokenReducer(
  state = initialState,
  action = {
    type: undefined
  }
) {
  switch (action.type) {
    case TOKEN_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore
          ? [...state.data, ...action.playload.data]
          : action?.playload.data
      };
    case TOKEN_FETCH_ONE:
      return { ...state, ...action.playload };
    case TOKEN_CLEAR:
      return { ...state, status: undefined, message: undefined };
    default:
      return { ...state };
  }
}
