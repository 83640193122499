import _ from 'lodash';
import { IS_LOADING } from '../actions/types';

const initialState = {
  status: false,
  message: 'Loading',
  names: {}
};

export default function LoadingReducer(state = initialState, action = {
  type: undefined
}) {
  const { names } = state;

  switch (action.type) {
    case IS_LOADING:
      if (_.has(action, 'names') && _.keys(action.names).length > 0) {
        _.keys(action.names).forEach(item => {
          names[item] = action.names[item];
        });
      }
      return {
        ...state,
        status: action.status,
        message: action.message,
        names
      };
    default:
      return { ...state };
  }
}
