import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  DOCTOR_WALLET_CLEAR,
  DOCTOR_WALLET_SUMMARY,
  DOCTOR_WALLET_HISTORY
} from './types';

export const getDoctorWallet = docCode => async dispatch => {
  const url = `wallet/doctor/${docCode}`;

  dispatch({ type: DOCTOR_WALLET_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_WALLET_SUMMARY: true } });
  await axios.get(url).then(response => {
    const detail = response?.data || {};
    dispatch({ type: DOCTOR_WALLET_SUMMARY, playload: { detail, status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_WALLET_SUMMARY: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: DOCTOR_WALLET_SUMMARY, playload: { detail: {}, status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_WALLET_SUMMARY: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getWalletLists = (docCode, params = [], limit = 100, nextKey = '') => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];

  let url = `wallet/history/${docCode}?${newParams.join('&')}`;

  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: DOCTOR_WALLET_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_WALLET_HISTORY: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: DOCTOR_WALLET_HISTORY,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_WALLET_HISTORY: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: DOCTOR_WALLET_HISTORY, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_WALLET_HISTORY: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
