import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  INSURANCE_FETCH,
  INSURANCE_CLEAR
} from './types';

export const getInsurances = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '') => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `insurances?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: INSURANCE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { INSURANCE_FETCH: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: INSURANCE_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: INSURANCE_FETCH, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { INSURANCE_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const addInsurance = async data => {
  const url = '/insurances';
  return new Promise(reslove => {
    axios.post(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};
