import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import moment from 'moment-timezone';
import classNames from 'classnames';

import { firebase, database } from '../helpers/firebase';
import { DEFAULT_CREATE_BY_APP } from '../configs';
import {
  ALERT_CLEAR,
  ALERT_LISTS,
  ALERT_NOTIFICATION_UPDATE,
  IS_LOADING
} from './types';

export const alert = ({
  title,
  message,
  datetime,
  icon,
  fill = true,
  target = '_self',
  link = '#',
  type = 'success'
}) => {
  const Container = props => props.children;
  if (fill) {
    const html = (
      <Container>
        <Row>
          <Col className="alert-block">
            {title && (
              <div className="alert-title text-pbb">{title}</div>
            )}
            {message && (
              <div className="alert-message text-sb">{message}</div>
            )}
          </Col>
        </Row>
      </Container>
    );

    if (type === 'warning') {
      toast.warn(html, { theme: 'colored', closeButton: false, icon: <Icon icon={icon || 'ion:alert-circle'} width="48px" /> });
    } else if (['error', 'danger'].includes(type)) {
      toast.error(html, { theme: 'colored', closeButton: false, icon: <Icon icon={icon || 'ion:close-circle'} width="48px" /> });
    } else {
      toast.success(html, { theme: 'colored', closeButton: false, icon: <Icon icon={icon || 'ion:checkmark-circle'} width="48px" /> });
    }
  } else {
    const html = (
      <>
        <Row
          className={classNames('mx-0', { 'alert-block': datetime })}
          tag={link !== '#' ? 'a' : 'div'}
          href={link}
          target={target}
        >
          {icon && (
            <Col xs="auto" className="px-0">
              <div className={classNames('alert-icon', { [`text-${type}`]: true })}>
                <Icon icon={icon} width="48px" />
              </div>
            </Col>
          )}
          <Col className={classNames('alert-text-block')}>
            {title && (
              <div className={classNames('alert-title text-pbb', { [`text-${type}`]: true })}>{title}</div>
            )}
            {message && (
              <div className="alert-message text-sb">{message}</div>
            )}
          </Col>
        </Row>
        {datetime && (
          <span className="alert-time">{moment(datetime).fromNow()}</span>
        )}
      </>
    );
    toast(html);
  }
};

export const notification = (status, activity, patCode, docCode, message) => {
  const params = {
    activity,
    form: {
      code: docCode,
      role: 'doctor'
    },
    to_code: patCode,
    to_role: 'patient',
    message,
    readBy: ['NO-READ'],
    alertBy: ['NO-ALERT'],
    roleAction: {
      patient: true
    },
    status,
    createAt: moment().format('x'),
    updateAt: moment().format('x')
  };
  firebase.set(firebase.push(firebase.ref(database, 'notifications')), params);
};

export const createNotification = (data, os = 'ANDROID') => {
  const url = '/notifications';
  const params = {
    ...data,
    os
  };
  axios.post(url, params).then(() => {
  }).catch(() => {
  });
};

export const notifications = (type, status, patCode, refCode, docCode, message = '', createByApp = DEFAULT_CREATE_BY_APP, options = {}) => {
  const createAt = moment().format('x');
  const params = {
    form: {
      code: docCode,
      role: 'doctor'
    },
    refCode,
    to_code: patCode,
    to_role: 'patient',
    patient_read: `${patCode}_false_${createByApp}`,
    message,
    readBy: ['NO-READ'],
    alertBy: ['NO-ALERT'],
    roleAction: {
      patient: true
    },
    status,
    createAt,
    updateAt: moment().format('x'),
    ...options
  };
  firebase.set(firebase.push(firebase.ref(database, type)), params);
};

export const sendNotiByRefCode = (refCode, event) => async dispatch => {
  const url = '/notifications/byReferralRefCode';
  const dataSet = {
    refCode,
    event
  };

  dispatch({ type: ALERT_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { ALERT_NOTIFICATION_UPDATE: true } });
  await axios.post(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: ALERT_NOTIFICATION_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { ALERT_NOTIFICATION_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: ALERT_NOTIFICATION_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { ALERT_NOTIFICATION_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const setListsNotification = data => async dispatch => {
  dispatch({ type: ALERT_CLEAR });
  dispatch({ type: ALERT_LISTS, playload: { status: 'success', lists: data } });
};
