import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SignalDisconnected = ({
  isOpen,
  callBack
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {(isOpen) && false && (
      <div className="network-signal" id="btn-callback" onClick={() => { callBack(); }} aria-hidden="true" style={{ cursor: 'pointer' }}>
        <div style={{ color: '#1D295A' }}>{t('Real-time connection in 5 seconds')}</div>
        <div style={{ color: '#1D295A' }}>{t('Click to connect now')}</div>
      </div>
      )}
    </div>
  );
};

SignalDisconnected.propTypes = {
  isOpen: PropTypes.bool,
  callBack: PropTypes.func
};

SignalDisconnected.defaultProps = {
  isOpen: false,
  callBack: () => {}
};

export default SignalDisconnected;
