import {
  FEEDBACK_SEND,
  FEEDBACK_CLEAR
} from '../actions/types';

const initialState = {
  data: {}
};

export default function FeedbackReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case FEEDBACK_SEND:
      return { ...state, ...action.playload };
    case FEEDBACK_CLEAR:
      return { ...state, status: undefined };
    default:
      return { ...state };
  }
}
