import _ from 'lodash';

const [, , subdomain] = window.location.hostname.split('.').reverse();

export const DEFAULT_DOCTOR_RATE = 80;
export const BACKEND_ENDPOINT = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
export const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL;
export const COOKIE_SECURE = process.env.REACT_APP_COOKIE_SECURE;
export const DEFAULT_CREATE_BY_APP = process.env.REACT_APP_DEFAULT_CREATE_BY_APP;
export const DEFAULT_CREATE_BY_APP_NAME = process.env.REACT_APP_DEFAULT_CREATE_BY_APP_NAME;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const DEFAULT_SUB_DOMAIN = process.env.REACT_APP_DEFAULT_SUB_DOMAIN;
export const DEFAULT_DOMAIN = process.env.REACT_APP_DEFAULT_DOMAIN;
export const SUB_DOMAIN = _.isUndefined(subdomain) ? DEFAULT_SUB_DOMAIN : subdomain;
export const SHORT_LINK_URL = process.env.REACT_APP_SHORT_LINK_URL;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
export const PAYMENT_METHOD = process.env.REACT_APP_PAYMENT_METHOD;

export const CLAIM_ENDPOINT = process.env.REACT_APP_CLAIM_ENDPOINT;
export const CLAIM_AUTH_PROVIDE = process.env.REACT_APP_CLAIM_AUTH_PROVIDER;

export const EXPO_SERVER = process.env.REACT_APP_EXPO_SERVER;
export const EXPO_MODE = process.env.REACT_APP_EXPO_MODE;
export const TWILIO_ACCOUNT_SID = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
export const TWILIO_API_KEY_SID = process.env.REACT_APP_TWILIO_API_KEY_SID;
export const TWILIO_API_KEY_SECRET = process.env.REACT_APP_TWILIO_API_KEY_SECRET;
export const LINE_CLIENT_ID = process.env.REACT_APP_LINE_CLIENT_ID;
export const LINE_CLIENT_SECRET = process.env.REACT_APP_LINE_CLIENT_SECRET;
export const LINE_NONCE = process.env.REACT_APP_LINE_NONCE;
export const LINE_REDIRECT_URI = process.env.REACT_APP_LINE_REDIRECT_URI;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const DELIVERY_FEE = process.env.REACT_APP_DELIVERY_FEE;
export const DISTANCE_FEE = process.env.REACT_APP_DISTANCE_FEE;
export const TISCO_INSURE_KEY_DECRYPT = process.env.REACT_APP_TISCO_INSURE_KEY_DECRYPT;
export const SHARMBLE_URL = process.env.REACT_APP_SHARMBLE_URL;
export const SHARMBLE_API_KEY = process.env.REACT_APP_SHARMBLE_API_KEY;
export const SHARMBLE_API_SECRET = process.env.REACT_APP_SHARMBLE_API_SECRET;
export const TIME_SHOW_MISS_CALL = process.env.REACT_APP_TIME_SHOW_MISS_CALL;
export const WAITING_TIME_BOOKING = process.env.REACT_APP_WAITING_TIME_BOOKING;

export const EKYC_URL = process.env.REACT_APP_EKYC_URL;

export const SIGNAL_HOST = process.env.REACT_APP_SIGNAL_HOST;
export const SIGNAL_PORT = process.env.REACT_APP_SIGNAL_PORT;
export const SIGNAL_PATH = process.env.REACT_APP_SIGNAL_PATH;
export const DOMAIN_SERVER_MEETING = process.env.REACT_APP_DOMAIN_SERVER_MEETING;
export const WAITING_PERIOD_CALL = process.env.REACT_APP_WAITING_PERIOD_CALL;
export const WAITING_REQUEST_CALL_NOW = process.env.REACT_APP_WAITING_REQUEST_CALL_NOW;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;
export const APPLE_USE_POPUP = true;
export const SMS_PROVIDER_NAME = process.env.REACT_APP_SMS_PROVIDER_NAME;
export const OLD_VERSION_REDIRECT_URI = process.env.REACT_APP_OLD_VERSION_REDIRECT_URI;
export const USE_WAITING_CALL = process.env.REACT_APP_USE_WAITING_CALL;
export const USE_EKYC = process.env.REACT_APP_USE_EKYC;
export const API_ACCESS_TOKEN = process.env.REACT_APP_API_ACCESS_TOKEN;
export const ROLE_ACCEPT = [
  'admin-hospital',
  'admin-accounting',
  'systemadmin'
];

export const PAY_DOCTOR_RATE = process.env.REACT_APP_DEFAULT_PAY_DOCTOR_RATE;
export const LINK_VIDEO_ROOM = process.env.REACT_APP_LINK_VIDEO_ROOM;

// Document
export const DOCUMENTS = [
  { value: '0001', label: 'Standard DAZ' },
  { value: '0002', label: 'Custom Cigna' },
  { value: '0003', label: 'Custom Ocean Life' },
  { value: '0004', label: 'Custom TRPH' },
  { value: '0005', label: 'Custom BANPU' },
  { value: '0006', label: 'Custom MMT' },
  { value: '0007', label: 'Custom CHG' },
  { value: '0008', label: 'Custom KORAT' },
  { value: '0009', label: 'Custom YALA' },
  { value: '0010', label: 'Custom PRINC' }
];

export const LIST_PHARMACIES = [
  { value: 'sharmble', label: 'Sharmble', useDropPoint: true },
  { value: 'mmt', label: 'MMT', useDropPoint: false }
];
