import {
  MASTER_CLEAR,
  MASTER_DATA,
  MASTER_DOCTOR_PREFIX,
  MASTER_GROUP_SPECIALTIES,
  MASTER_TAGS_AND_EVENTS,
  MASTER_APPS,
  MASTER_MANUALS,
  MASTER_FAQS,
  MASTER_SYMPTOM,
  MASTER_ORGAN,
  MASTER_UPDATE,
  MASTER_ICD10,
  MASTER_METHOD_SERVICE
} from '../actions/types';

const initialState = {
  doctorPrefixs: [],
  groupSpecialties: [],
  tagsAndEvents: [],
  apps: [],
  manuals: [],
  faqs: [],
  symptoms: [],
  organs: [],
  icd10: [],
  DATOZ_parameter: {},
  medthodService: []
};

export default function MasterReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case MASTER_DATA:
      return { ...state, ...action.playload, DATOZ_parameter: action.playload };
    case MASTER_DOCTOR_PREFIX:
      return { ...state, ...action.playload };
    case MASTER_GROUP_SPECIALTIES:
      return { ...state, ...action.playload };
    case MASTER_TAGS_AND_EVENTS:
      return { ...state, ...action.playload };
    case MASTER_APPS:
      return { ...state, ...action.playload };
    case MASTER_MANUALS:
      return { ...state, ...action.playload };
    case MASTER_FAQS:
      return { ...state, ...action.playload };
    case MASTER_SYMPTOM:
      return { ...state, ...action.playload };
    case MASTER_ORGAN:
      return { ...state, ...action.playload };
    case MASTER_UPDATE:
    case MASTER_ICD10:
      return { ...state, ...action.playload };
    case MASTER_METHOD_SERVICE:
      return { ...state, ...action.playload };
    case MASTER_CLEAR:
      return { ...state, status: undefined };
    default:
      return { ...state };
  }
}
