import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Loading = ({ message }) => {
  const { t } = useTranslation();

  return (
    <section className="loading">
      <div className="loading-block">
        <div className="lds-ellipsis">
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
        {message ? (
          <div className="text-capitalize">{t(message)}</div>
        ) : (
          <div className="text-capitalize">{t('Loading')}</div>
        )}
      </div>
    </section>
  );
};

Loading.propTypes = {
  message: PropTypes.string
};

Loading.defaultProps = {
  message: undefined
};

export default Loading;
