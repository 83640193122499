import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { Icon } from '@iconify/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { getDatetimeLocal } from '../helpers/utilities';

const PrepareVideo = ({
  data,
  callback,
  callbackClose
}) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [time, setTime] = useState('');

  useEffect(() => {
    if (_.has(data, 'refCode')) {
      setName(`${t('Patient')} ${data.patientFirstName} ${data.patientLastName}`);
      let timeSlotRange = 15;
      if (_.has(data, 'information.timeSlotRange')) {
        timeSlotRange = data.information.timeSlotRange;
      }
      const startTime = `${getDatetimeLocal(`${data.information.visitDate} ${data.information.visitTime}`, 'DD MMMM YYYY HH:mm')} ${(i18n.language === 'th' ? ' น.' : '')}`;
      const endTime = `${getDatetimeLocal(moment(`${data.information.visitDate} ${data.information.visitTime}`).add(timeSlotRange, 'minutes').format('YYYY-MM-DD HH:mm'), 'DD MMMM YYYY HH:mm')} ${(i18n.language === 'th' ? ' น.' : '')}`;
      setTime(`${startTime} ${endTime}`);
    }
  }, [i18n]);

  return (
    <div className="prepare-video">
      <div className="prepare-image">
        {_.has(data, 'mapImagePatient') ? (
          <img className="prepare-avatar" src={data.mapImagePatient} alt="" />
        ) : (
          <Icon icon="fa-solid:user" className="prepare-avatar-icon" />
        )}
      </div>
      <div className="prepare-name">{name}</div>
      <div className="prepare-text-info">{t('It\'s time to consult')}</div>
      <div className="prepare-text-time">{time}</div>
      <div className="prepare-action">
        <Button
          color="secondary"
          className="w-100"
          onClick={() => {
            callback(data);
          }}
        >
          <Icon icon="ion:videocam" />
          &nbsp;
          {t('Press the button to consult')}
        </Button>
        <div className="close-link">
          <Button
            color="primary"
            outline
            onClick={() => {
              callbackClose(data);
            }}
          >
            {t('Close this page and don\'t be reminded again')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PrepareVideo.propTypes = {
  data: PropTypes.instanceOf(Object),
  callback: PropTypes.func,
  callbackClose: PropTypes.func
};

PrepareVideo.defaultProps = {
  data: {},
  callback: () => {},
  callbackClose: () => {}
};

export default PrepareVideo;
