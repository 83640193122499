import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  REPORT_CASE_CONSULT
} from './types';

export const getReportCaseConsult = (docCode, year, month) => async dispatch => {
  const url = `reports/json/referrals/${docCode}?year=${year}&month=${month.toString().padStart(2, '0')}`;

  dispatch({ type: IS_LOADING, status: true, names: { REPORT_CASE_CONSULT: true } });
  await axios.get(url).then(response => {
    dispatch({ type: REPORT_CASE_CONSULT, playload: { cases: response.data } });
    dispatch({ type: IS_LOADING, status: false, names: { REPORT_CASE_CONSULT: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { REPORT_CASE_CONSULT: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const exportReport = async (type, dataSet) => {
  const url = `reports/excel/${type}`;
  return new Promise(reslove => {
    axios.post(url, dataSet).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove(error);
    });
  });
};

export const dashboardGetCount = async (params, data, nextKey, loop) => {
  let dataResult = {};
  if (_.has(data, 'count_all')) {
    dataResult = data;
  }
  let url = '/dashboard/count';
  if (params) {
    url += `?${params}`;
  }
  if (nextKey !== '') {
    url += `&nextKey=${nextKey}`;
  }
  return new Promise(reslove => {
    axios.get(url).then(response => {
      if (_.has(response.data, 'count_booking')) {
        if (_.has(dataResult, 'count_all')) {
          dataResult.count_all += response.data.count_all;
          dataResult.count_completed += response.data.count_completed;
          dataResult.count_booking += response.data.count_booking;
          dataResult.count_incomplete += response.data.count_incomplete;
          dataResult.count_doctor_prescribes_medicine += response.data.count_doctor_prescribes_medicine;
          dataResult.count_patient_takes_medicine += response.data.count_patient_takes_medicine;
          dataResult.count_payment_cash += response.data.count_payment_cash;
          dataResult.count_payment_credit += response.data.count_payment_credit;
          dataResult.count_payment_coupon += response.data.count_payment_coupon;
          dataResult.count_payment_insurance += response.data.count_payment_insurance;
          dataResult.count_payment_other += response.data.count_payment_other;
          dataResult.count_application += response.data.count_application;
          dataResult.count_web_application += response.data.count_web_application;
          dataResult.count_line_oa += response.data.count_line_oa;
          dataResult.count_easy_call += response.data.count_easy_call;
        } else {
          dataResult = response.data;
        }
      } else if (!_.has(response.data, 'count_booking') && !_.has(response.data, 'count_iot_all')) {
        if (_.has(dataResult, 'count_all')) {
          dataResult.count_all += response.data.count_all;
          dataResult.count_application += response.data.count_application;
          dataResult.count_web_application += response.data.count_web_application;
          dataResult.count_admin += response.data.count_admin;
        } else {
          dataResult = response.data;
        }
      } else if (_.has(response.data, 'count_iot_all')) {
        if (_.has(dataResult, 'count_iot_patient')) {
          dataResult.count_iot_patient += response.data.count_iot_patient;
          dataResult.count_iot_all += response.data.count_iot_all;
          dataResult.count_iot_weight += response.data.count_iot_weight;
          dataResult.count_iot_height += response.data.count_iot_height;
          dataResult.count_iot_alarm += response.data.count_iot_alarm;
          dataResult.count_iot_pulse_oximeter += response.data.count_iot_pulse_oximeter;
          dataResult.count_iot_blood_pressure_meter += response.data.count_iot_blood_pressure_meter;
          dataResult.count_iot_glucose_meter += response.data.count_iot_glucose_meter;
          dataResult.count_iot_body_thermo_meter += response.data.count_iot_body_thermo_meter;
          dataResult.count_iot_stethoscope += response.data.count_iot_stethoscope;
          dataResult.count_iot_ecg += response.data.count_iot_ecg;
          dataResult.count_iot_otoscope += response.data.count_iot_otoscope;
        } else {
          dataResult = response.data;
        }
      }
      if (_.has(response.data, 'nextKey') && response.data.nextKey !== '' && response.data.nextKey !== 'null' && loop < 100) {
        reslove(dashboardGetCount(params, dataResult, response.data.nextKey, loop + 1));
      }
      reslove(dataResult);
    }).catch(error => {
      reslove(error);
    });
  });
};
