import {
  ADMIN_UPDATE,
  ADMIN_CREATE,
  ADMIN_FETCH_ONE,
  ADMIN_CLEAR,
  ADMIN_FETCH
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  wallet: {},
  nextKey: ''
};

export default function AdminReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case ADMIN_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case ADMIN_UPDATE:
    case ADMIN_CREATE:
    case ADMIN_FETCH_ONE:
      return { ...state, ...action.playload };
    case ADMIN_CLEAR:
      return { ...state, status: undefined, message: undefined };
    default:
      return { ...state };
  }
}
