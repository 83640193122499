import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  MASTER_DATA,
  MASTER_DOCTOR_PREFIX,
  MASTER_GROUP_SPECIALTIES,
  MASTER_TAGS_AND_EVENTS,
  MASTER_APPS,
  MASTER_MANUALS,
  MASTER_FAQS,
  MASTER_SYMPTOM,
  MASTER_ORGAN,
  MASTER_ICD10,
  MASTER_METHOD_SERVICE,
  MASTER_UPDATE,
  MASTER_CLEAR
} from './types';
import icd10 from '../assets/data/icd10.json';

export const masterData = () => async dispatch => {
  const url = 'master';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_DATA: true } });
  await axios.get(url).then(response => {
    const data = _.has(response, 'data') ? response.data : {};
    dispatch({ type: MASTER_DATA, playload: data });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterDoctorPrefix = () => async dispatch => {
  const url = 'master/doctorPrefix';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_DOCTOR_PREFIX: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_DOCTOR_PREFIX, playload: { doctorPrefixs: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterGroupSpecialties = () => async dispatch => {
  const url = 'master/groupSpecialtys';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_GROUP_SPECIALTIES: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_GROUP_SPECIALTIES, playload: { groupSpecialties: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterTagsAndEvents = () => async dispatch => {
  const url = 'master/tagsAndEvents?onPeriod=1';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_TAGS_AND_EVENTS: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_TAGS_AND_EVENTS, playload: { tagsAndEvents: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterApps = () => async dispatch => {
  const url = 'master/apps';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_APPS: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_APPS, playload: { apps: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterManuals = () => async dispatch => {
  const url = 'master/manuals';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_MANUALS: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_MANUALS, playload: { manuals: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterFAQs = () => async dispatch => {
  const url = 'master/faqs';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_FAQS: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_FAQS, playload: { faqs: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterSymptom = () => async dispatch => {
  const url = 'master/symptom';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_SYMPTOM: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_SYMPTOM, playload: { symptoms: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterOrgan = () => async dispatch => {
  const url = 'master/organ';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_ORGAN: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_ORGAN, playload: { organs: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterUpdate = (masterId, params) => async dispatch => {
  const url = `master/${masterId}`;

  dispatch({ type: MASTER_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { MASTER_UPDATE: true } });
  await axios.put(url, params).then(() => {
    dispatch({ type: MASTER_UPDATE, playload: { status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const masterIcd10 = () => async dispatch => {
  dispatch({ type: MASTER_ICD10, playload: { icd10 } });
};

export const updateMaster = async data => {
  const url = '/master/DATOZ_parameter';
  return new Promise(reslove => {
    axios.put(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const getMasterMethodServices = () => async dispatch => {
  const url = 'master/methodService';

  dispatch({ type: IS_LOADING, status: true, names: { MASTER_METHOD_SERVICE: true } });
  await axios.get(url).then(response => {
    dispatch({ type: MASTER_METHOD_SERVICE, playload: { medthodService: _.has(response.data, 'data') ? response.data.data : [] } });
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_METHOD_SERVICE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { MASTER_METHOD_SERVICE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getMasterWhitelabel = async () => {
  const url = '/master/whitelabelAdmin';
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};
