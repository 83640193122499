const WhiteLabelConfig = {
  mapping: [
    // Dev
    // {
    //   companyCode: 'CN1562652993102',
    //   appCode: 'com.agyhero.dev',
    //   domainName: 'devadmin.agyhero.com',
    //   companyName: 'DAZ'
    // },
    {
      companyCode: 'CN1598928649546',
      appCode: 'co.doctoratoz.mmt',
      domainName: 'devadminmmt.agyhero.com',
      companyName: 'มิตรไมตรีคลินิก (mmt)'
    },
    {
      companyCode: 'CN1610705650771',
      appCode: 'co.doctoratoz.chularat',
      domainName: 'devadminchg.agyhero.com',
      companyName: 'CHG Company'
    },
    // Prod
    // {
    //   companyCode: 'CN1568780494050',
    //   appCode: 'com.agyhero.dev',
    //   domainName: 'admin.agyhero.com',
    //   companyName: 'AGY HERO'
    // },
    {
      companyCode: 'CN1688117055706',
      appCode: 'co.doctoratoz.puhealth',
      domainName: 'adminpuhealth.agyhero.com',
      companyName: 'กรมอนามัย'
    },
    {
      companyCode: 'CN1691739003644',
      appCode: 'co.doctoratoz.bkkgi',
      domainName: 'admin.healthtalkth.com',
      companyName: 'BkkGi'
    },
    {
      companyCode: 'CN1693232674837',
      appCode: 'co.doctoratoz.telepharmacy',
      domainName: 'admintelepharmacy.agyhero.com',
      companyName: 'Telepharmacy'
    },
    {
      companyCode: 'CN1610705529244',
      appCode: 'co.doctoratoz.chularat',
      domainName: 'adminchg.agyhero.com',
      companyName: 'CHG Company'
    },
    {
      companyCode: 'CN1658466861097',
      appCode: 'co.doctoratoz.wtcare',
      domainName: 'adminwt.agyhero.com',
      companyName: 'วังทอง'
    },
    {
      companyCode: 'CN1699870778015',
      appCode: 'co.doctoratoz.suphan',
      domainName: 'adminsuphan.agyhero.com',
      companyName: 'อบจ.สุพรรณบุรี'
    },
    {
      companyCode: 'CN1621321597697',
      appCode: 'co.doctoratoz.chiva',
      domainName: 'adminchiva.agyhero.com',
      companyName: 'CHIVA HISxHER'
    },
    {
      companyCode: 'CN1600835175233',
      appCode: 'co.doctoratoz.mmt',
      domainName: 'adminmmt.agyhero.com',
      companyName: 'มิตรไมตรีคลินิก (mmt)'
    },
    {
      companyCode: 'CN1660883798367',
      appCode: 'co.doctoratoz.chersery',
      domainName: 'adminch.agyhero.com',
      companyName: 'เฌ้อเซอรี่ โฮม'
    }
  ]
};

export default WhiteLabelConfig;
