import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import moment from 'moment-timezone';

import {
  alert,
  referralUpdate,
  sendNotiByRefCode,
  updateRealtime
} from '../../actions';
import imgCounting from '../../assets/images/counting.gif';
import { DEFAULT_CREATE_BY_APP, WAITING_REQUEST_CALL_NOW } from '../../configs';
import { database, firebase } from '../../helpers/firebase';
import { secToMunite } from '../../helpers/utilities';

let timerInterval = null;
const RequestCallNowNotOnline = ({
  data,
  isOpen,
  toggle
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [process, setProcess] = useState('');
  const [timeWaiting] = useState(60 * WAITING_REQUEST_CALL_NOW);
  const [timeRemaining, setTimeRemaining] = useState('00:00');
  const [isRequestAgain, setIsRequestAgain] = useState(false);
  const [message, setMessage] = useState('');
  const [dateCreate] = useState(moment().format('x'));

  const referral = useSelector(selector => selector.referral);
  const realtime = useSelector(selector => selector.realtime);

  const callNow = useCallback(input => {
    dispatch(referralUpdate(input.refCode, {
      feedback_comment: 'null',
      feedback_rate_start: 'null'
    }));
    dispatch(updateRealtime('connect-signal', true, {
      refCode: input.refCode,
      patCode: input.patCode,
      createByApp: input.createByApp,
      patientFirstName: input.patientFirstName,
      patientLastName: input.patientLastName,
      patientImage: input.mapImagePatient,
      timeSlotRange: input.information.timeSlotRange,
      patientMobile: input.patientMobile,
      createByAppName: input.createByAppName,
      useWeb: input.useWeb,
      shortLinkForComplete: input.shortLinkForComplete,
      referral: input,
      callFromDoctor: true
    }));
  }, [realtime]);

  useEffect(() => {
    const notifications = firebase.query(firebase.ref(database, 'notifications'), firebase.orderByChild('to_code'), firebase.equalTo(data.information.doctor), firebase.limitToLast(1));
    firebase.onChildAdded(notifications, snapshot => {
      const value = snapshot.val();
      if (moment(value.createAt, 'x').isSameOrAfter(moment(dateCreate, 'x'))) {
        if (value.status === 'CONFIRM_REQUEST_CALL_NOW') {
          clearInterval(timerInterval);
          setProcess('stop');
          toggle();
          callNow(data);
        } else if (value.status === 'REJECT_REQUEST_CALL_NOW') {
          setMessage(t('Patient Reject'));
          setIsRequestAgain(true);
          setProcess('stop');
          clearInterval(timerInterval);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (referral.status && referral.status !== undefined && process === 'referral-update') {
      setProcess('stop');
      if (referral.status === 'success') {
        alert({
          type: 'success',
          title: t('Successful'),
          message: t(referral.message),
          icon: 'ion:checkmark-circle'
        });
        setProcess('waiting');
        setMessage(t('Patient did not respond in time'));
        let time = timeWaiting;
        setTimeRemaining(secToMunite(timeWaiting));
        timerInterval = setInterval(() => {
          if (time <= 0) {
            setProcess('stop');
            setIsRequestAgain(true);
            clearInterval(timerInterval);
          } else {
            time -= 1;
            setTimeRemaining(secToMunite(time));
          }
        }, 1000);
      } else {
        alert({
          type: 'danger',
          title: t('An error occurred'),
          message: t(referral.message || 'Sorry something went wrong. Please contact support'),
          icon: 'ion:close-circle'
        });
      }
    }
  }, [referral, process, setProcess]);

  const confirm = useCallback(() => {
    setProcess('referral-update');
    dispatch(referralUpdate(data.refCode, {
      information: {
        ...data.information,
        confirmCallNow: false,
        confirmCallNowAt: 'null'
      }
    }));
    const createAt = moment().format('x');
    const params = {
      form: {
        code: data.information.doctor,
        role: 'doctor'
      },
      refCode: data.refCode,
      to_code: data.patCode,
      to_role: 'patient',
      patient_read: `${data.patCode}_false_${data.createByApp || DEFAULT_CREATE_BY_APP}`,
      message: 'Doctor send request call now',
      readBy: ['NO-READ'],
      alertBy: ['NO-ALERT'],
      roleAction: {
        patient: true
      },
      status: 'REQUEST_CALL_NOW',
      createAt,
      updateAt: moment().format('x')
    };
    firebase.set(firebase.push(firebase.ref(database, 'activities')), params);
    setTimeout(() => {
      dispatch(sendNotiByRefCode(data.refCode, 'EVENT_D008'));
    }, 300);
  }, [referral]);

  useEffect(() => {
    if (referral.status && referral.status !== undefined && process === '') {
      confirm();
    }
  }, [referral]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="custom-modal" backdrop="static" keyboard={false}>
      {process === 'waiting' && (
        <>
          <ModalHeader className="text-center">{t('Please wait for the patient to confirm the call request now')}</ModalHeader>
          <ModalBody>
            <div className="label max-width-350 text-sb">
              {t('Patient will confirm your request within', { time: WAITING_REQUEST_CALL_NOW })}
            </div>
            <div className="label max-width-350 text-sb text-danger">
              {t('Do not leave this page, while pending confirm')}
            </div>
            <div className="text-center my-3">
              <img src={imgCounting} alt="" width="332" />
            </div>
            <div className="label text-danger text-pbb mb-2">
              {timeRemaining}
            </div>
          </ModalBody>
        </>
      )}
      {process !== 'waiting' && (
        isRequestAgain && (
          <>
            <ModalHeader className="text-center">
              <Icon icon="ion:alert-circle" width="40" className="text-danger" />
            </ModalHeader>
            <ModalBody>
              <div className="label request-not-response">
                {message}
              </div>
              <Row>
                <Col xs="6" sm={{ offset: 1, size: 5 }}>
                  <Button onClick={toggle} color="primary" outline size="sm" className="w-100">{t('For Later')}</Button>
                </Col>
                <Col xs="6" sm="5">
                  <Button onClick={confirm} color="primary" size="sm" className="w-100">{t('Request Again')}</Button>
                </Col>
              </Row>
            </ModalBody>
          </>
        )
      )}
    </Modal>
  );
};

RequestCallNowNotOnline.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

RequestCallNowNotOnline.defaultProps = {
  isOpen: false,
  toggle: () => {}
};

export default RequestCallNowNotOnline;
