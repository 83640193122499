import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  MEDICINE_FETCH_DETAIL,
  MEDICINE_CLEAR
} from './types';

export const getDroppoint = () => async dispatch => {
  const url = 'drug/dropPoint';

  dispatch({ type: MEDICINE_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { MEDICINE_FETCH_DETAIL: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData?.data?.drop_point) || []);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: MEDICINE_FETCH_DETAIL, playload: { dropPoint: data, status } });
    dispatch({ type: IS_LOADING, status: false, names: { MEDICINE_FETCH_DETAIL: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: MEDICINE_FETCH_DETAIL, playload: { status: error } });
    dispatch({ type: IS_LOADING, status: false, names: { MEDICINE_FETCH_DETAIL: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const medicineClear = () => dispatch => {
  dispatch({ type: MEDICINE_CLEAR });
};
