import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  HOSPITAL_CLEAR,
  HOSPITAL_LISTS,
  HOSPITAL_DATA,
  HOSPITAL_FETCH,
  HOSPITAL_CREATE,
  HOSPITAL_UPDATE
} from './types';

export const hospitalLists = () => async dispatch => {
  let url = 'hospitals/lists';
  const companyCode = localStorage.getItem('company-code');
  if (companyCode) {
    url += `?refCompanyCode=${companyCode}`;
  }

  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_LISTS: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({ type: HOSPITAL_LISTS, playload: { lists: data } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LISTS: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_LISTS: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const hospitalData = () => async dispatch => {
  let url = 'hospitals';
  const companyCode = localStorage.getItem('company-code');
  if (companyCode) {
    url += `?refCompanyCode=${companyCode}`;
  }

  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_DATA: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || response.data || [];
    dispatch({ type: HOSPITAL_DATA, playload: { data } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getHospitals = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '', isLoading = true) => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `hospitals?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: HOSPITAL_CLEAR });
  dispatch({ type: IS_LOADING, status: isLoading, names: { HOSPITAL_FETCH: isLoading } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: HOSPITAL_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: HOSPITAL_FETCH, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const hospitalCreate = params => async dispatch => {
  const url = 'hospitals';

  dispatch({ type: HOSPITAL_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_CREATE: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    const data = (resData?.data || resData);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: HOSPITAL_CREATE, playload: { detail: data, status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_CREATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_CREATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const hospitalUpdate = (code, dataSet) => async dispatch => {
  const url = `hospitals/${code}`;

  dispatch({ type: HOSPITAL_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: HOSPITAL_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: HOSPITAL_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getHospitalByCode = async code => {
  const url = `/hospitals?code=${code}`;
  return new Promise(reslove => {
    axios.get(url).then(response => {
      reslove(response?.data);
    }).catch(() => {
      reslove('');
    });
  });
};

export const getHospital = hosCode => async dispatch => {
  const url = `hospitals?code=${hosCode}`;

  dispatch({ type: HOSPITAL_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { HOSPITAL_DATA: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.hosCode === hosCode);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: HOSPITAL_DATA, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { HOSPITAL_DATA: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
