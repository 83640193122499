import {
  COUPON_CLEAR,
  COUPON_FETCH,
  COUPON_CREATE,
  COUPON_UPDATE,
  COUPON_DATA
} from '../actions/types';

const initialState = {
  data: [],
  isAddMore: false,
  nextKey: ''
};

export default function CouponReducer(
  state = initialState,
  action = {
    type: undefined
  }
) {
  switch (action.type) {
    case COUPON_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore
          ? [...state.data, ...action.playload.data]
          : action?.playload.data
      };
    case COUPON_CREATE:
    case COUPON_UPDATE:
    case COUPON_DATA:
      return { ...state, ...action.playload };
    case COUPON_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
