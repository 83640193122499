import {
  WHITELABEL_SETTING,
  WHITELABEL_SETTING_THEME
} from '../actions/types';

const initialState = {
  data: {},
  theme: {}
};

export default function WhitelabelReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case WHITELABEL_SETTING:
      return { ...state, ...action.playload };
    case WHITELABEL_SETTING_THEME:
      return { ...state, theme: action.playload.data };
    default:
      return { ...state };
  }
}
