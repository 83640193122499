import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import { useCookies } from 'react-cookie';

import { doctorUpdate } from '../../actions';
import { COOKIE_SECURE } from '../../configs';

const HowToUse = ({
  docCode,
  isOpen,
  toggle
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, setCookie] = useCookies();

  const callToggle = () => {
    setCookie('firstUsed', 1, { path: '/', secure: COOKIE_SECURE === 'true', sameSite: 'Lax' });
    dispatch(doctorUpdate(docCode, { firstLogin: false }));
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="custom-modal" backdrop="static" keyboard={false}>
      <ModalBody>
        <Row>
          <Col className="text-center">
            <h3>{t('User manual')}</h3>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/Nu9j9dcFioI" title={t('User manual')} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={{ size: 4, offset: 4 }} sm={{ size: 8, offset: 2 }}>
            <Button className="w-100" color="primary" onClick={callToggle}>
              {t('Get started now')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

HowToUse.propTypes = {
  docCode: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

HowToUse.defaultProps = {
  isOpen: false,
  toggle: () => { }
};

export default HowToUse;
