import {
  DRUG_FETCH,
  DRUG_CLEAR
} from '../actions/types';

const initialState = {
  data: []
};

export default function DrugReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case DRUG_FETCH:
      return { ...state, ...action.playload };
    case DRUG_CLEAR:
      return { ...state, status: undefined, message: undefined };
    default:
      return { ...state };
  }
}
