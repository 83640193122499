import {
  REPORT_CASE_CONSULT
} from '../actions/types';

const initialState = {
  cases: {}
};

export default function ReportReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case REPORT_CASE_CONSULT:
      return { ...state, ...action.playload };
    default:
      return { ...state };
  }
}
