import {
  ALERT_NOTIFICATION_UPDATE,
  ALERT_LISTS,
  ALERT_CLEAR
} from '../actions/types';

const initialState = {
  lists: []
};
export default function AuthReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case ALERT_LISTS:
    case ALERT_NOTIFICATION_UPDATE:
      return { ...state, ...action.playload };
    case ALERT_CLEAR:
      return {
        ...state,
        message: undefined,
        status: undefined
      };
    default:
      return { ...state };
  }
}
