import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import { IS_LOADING, FEEDBACK_CLEAR, FEEDBACK_SEND } from './types';

export const feedbackSend = params => async dispatch => {
  const url = '/feedbacks';

  dispatch({ type: FEEDBACK_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { FEEDBACK_SEND: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: FEEDBACK_SEND, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { FEEDBACK_SEND: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: FEEDBACK_SEND, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { REFERRAL_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
