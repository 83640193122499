import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

const CallingWaiting = ({
  data,
  isOpen,
  toggle,
  callBack
}) => {
  const { t } = useTranslation();

  const requestCallNow = useCallback(() => {
    callBack('confirm', data);
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="custom-modal modal-calling" backdrop="static" keyboard={false}>
      <ModalHeader>
        {`${(data && data.patientFirstName) || ''} ${(data && data.patientLastName) || ''}`}
      </ModalHeader>
      <ModalBody>
        <div className="block-patient">
          <div>
            <div className="d-inline">{t('The patient is not online in the system You can request to call now and wait for the system to send a notification to the patient to enter the system. Or you can postpone the appointment to consult with the patient first by selecting the menu. postpone the consultation after pressing to cancel the call')}</div>
          </div>
        </div>
        <Row className="mt-3">
          <Col sm={{ offset: 4, size: 4 }} xs="6">
            <Button onClick={() => callBack('cancel', data)} color="secondary" outline className="w-100">{t('Cancel outgoing call')}</Button>
          </Col>
          <Col sm="4" xs="6">
            <Button onClick={requestCallNow} color="secondary" className="w-100 btn-icon">
              <Icon icon="ion:videocam" width="24" />
              {t('Request Call Now')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CallingWaiting.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  callBack: PropTypes.func
};

CallingWaiting.defaultProps = {
  isOpen: false,
  toggle: () => {},
  callBack: () => {}
};

export default CallingWaiting;
