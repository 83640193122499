import {
  REFERRAL_FETCH,
  REFERRAL_FETCH_ONE,
  REFERRAL_LAST_FETCH,
  REFERRAL_UPDATE,
  REFERRAL_CLEAR
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  lastData: [],
  nextKey: ''
};

export default function ReferralReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case REFERRAL_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case REFERRAL_FETCH_ONE:
    case REFERRAL_LAST_FETCH:
    case REFERRAL_UPDATE:
      return { ...state, ...action.playload };
    case REFERRAL_CLEAR:
      return {
        ...state,
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
