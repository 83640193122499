import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translations.json';
import translationTH from './locales/th/translations.json';

const resources = {
  en: { translation: translationEN },
  th: { translation: translationTH }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_DEBUG,
    initImmediate: false,
    fallbackLng: 'th',
    resources,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
