import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  DRUG_FETCH,
  DRUG_CLEAR
} from './types';

export const getDrugByRefCode = code => async dispatch => {
  const url = `drug/medicine?refCode=${code}`;

  dispatch({ type: DRUG_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { DRUG_FETCH: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: DRUG_FETCH, playload: { data, status } });
    dispatch({ type: IS_LOADING, status: false, names: { DRUG_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { DRUG_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const drugClear = () => dispatch => {
  dispatch({ type: DRUG_CLEAR });
};

export const drugCreateOrder = async refCode => {
  const url = 'drug/order/create';
  return new Promise(reslove => {
    axios.post(url, { refCode }).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove(error);
    });
  });
};
