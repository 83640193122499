import { REALTIME_DATA, REALTIME_SIGNAL, REALTIME_SIGNAL_GUEST } from '../actions/types';

const initialState = {
  name: '',
  status: false,
  data: {},
  signalStatus: '',
  guestAction: ''
};

export default function RealtimeReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case REALTIME_DATA:
      return { ...state, ...action.playload };
    case REALTIME_SIGNAL:
      return { ...state, ...action.playload };
    case REALTIME_SIGNAL_GUEST:
      return { ...state, ...action.playload };
    default:
      return { ...state };
  }
}
