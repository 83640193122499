import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { alert } from '../../actions';
import { getPatientImageBase64 } from '../../helpers/utilities';

const Calling = ({
  data,
  isOpen,
  toggle,
  callBack
}) => {
  const { t } = useTranslation();
  const [process, setProcess] = useState('');
  const [img, setImg] = useState('');

  const referral = useSelector(selector => selector.referral);

  useEffect(() => {
    if (data?.patientImageBase64 && !['', 'null'].includes(data?.patientImageBase64)) {
      setImg(data.patientImageBase64);
    } else if (data.patCode) {
      const funcGetImage = async () => {
        const imgPath = await getPatientImageBase64(data.patCode);
        if (imgPath && !['', 'null'].includes(imgPath)) {
          setImg(imgPath);
        }
      };
      funcGetImage();
    }
  }, [data]);

  useEffect(() => {
    if (referral.status && referral.status !== undefined && process === 'referral-update') {
      if (referral.status === 'success') {
        setProcess('');
        alert({
          type: 'success',
          title: t('Successful'),
          message: t(referral.message),
          icon: 'ion:checkmark-circle'
        });
      } else {
        setProcess('');
        alert({
          type: 'danger',
          title: t('An error occurred'),
          message: t(referral.message || 'Sorry something went wrong. Please contact support'),
          icon: 'ion:close-circle'
        });
      }
    }
  }, [referral]);

  const accept = useCallback(() => {
    callBack('accept', data.patCode);
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} centered className="custom-modal modal-calling" backdrop="static" keyboard={false}>
      <ModalHeader>{`${t('Incoming Call')}...`}</ModalHeader>
      <ModalBody>
        <div className="block-patient">
          <div className={classNames('block-img-profile', { image: img && !['', 'null'].includes(img) })}>
            {(img && !['', 'null'].includes(img)) ? <img src={img} alt={data.patCode} /> : <Icon icon="fa-solid:user" className="icon-profile" width="44px" />}
          </div>
          <div className="block-detail-profile">
            <h1 className="title font-32">{`${(data && data.patientFirstName) || ''} ${(data && data.patientLastName) || ''}`}</h1>
            <div className="d-inline font-20">{t('Making a video call to you')}</div>
          </div>
        </div>
        <Row className="mt-3">
          <Col sm={{ offset: 4, size: 4 }} xs="6">
            <Button onClick={() => callBack('reject', data.patCode)} color="secondary" outline className="w-100">{t('Reject Consult')}</Button>
          </Col>
          <Col sm="4" xs="6">
            <Button onClick={accept} color="secondary" className="w-100 btn-icon">
              <Icon icon="ion:videocam" width="24" />
              {t('Accept Calling')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

Calling.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  callBack: PropTypes.func
};

Calling.defaultProps = {
  isOpen: false,
  toggle: () => {},
  callBack: () => {}
};

export default Calling;
