import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  CHATS_SEND_MESSAGE,
  CHATS_LIST,
  CHATS_CLEAR
} from './types';

export const chatList = (refCode, patCode) => async dispatch => {
  let url = `chat?role=doctor&refCode=${refCode}&userCode=${patCode}&limit=100`;
  const columns = ['role', 'message', 'read', 'type', 'fileType', 'createdAt'];
  if (columns.length > 0) {
    url += '&attrType=custom';
    columns.forEach((column, key) => {
      url += `&attributes[${key}]=${column}`;
    });
  }
  dispatch({ type: IS_LOADING, status: true, names: { CHATS_LIST: true } });
  dispatch({ type: CHATS_CLEAR });
  await axios.get(url).then(response => {
    dispatch({ type: CHATS_LIST, playload: { dataAll: response.data.data, status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { CHATS_LIST: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { CHATS_LIST: false } });
    dispatch({ type: CHATS_LIST, playload: { dataAll: [], status: 'success' } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const chatSendMessage = (refCode, docCode, message, type = 'message', fileType = 'image') => async dispatch => {
  const url = 'chat';
  const params = {
    role: 'doctor',
    type,
    refCode,
    code: docCode,
    message
  };
  if (type === 'file') {
    params.fileType = fileType;
  }
  dispatch({ type: CHATS_CLEAR });
  await axios.post(url, params).then(response => {
    let sent = true;
    if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
      sent = false;
    } else if (_.has(response.data, 'error')) {
      sent = false;
    }
    const resData = response?.data?.data || {};
    dispatch({ type: CHATS_SEND_MESSAGE, playload: { data: resData, sent, status: 'success' } });
  }).catch(err => {
    let error = err;
    dispatch({ type: CHATS_SEND_MESSAGE, playload: { data: {}, sent: false, status: 'success' } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const chatRead = (refCode, docCode) => async () => {
  const url = `/chat/read/${refCode}`;
  await axios.put(url, { role: 'doctor', userCode: docCode }).then(() => {

  }).catch(err => {
    let error = err;
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const chatListByDoctor = docCode => async dispatch => {
  const url = `chat/byDoctor/${docCode}`;
  dispatch({ type: IS_LOADING, status: true, names: { CHATS_LIST: true } });
  dispatch({ type: CHATS_CLEAR });
  await axios.get(url).then(response => {
    dispatch({ type: CHATS_LIST, playload: { lists: response.data.data, status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { CHATS_LIST: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: CHATS_LIST, playload: { lists: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { CHATS_LIST: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};
