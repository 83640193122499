export const IS_LOADING = 'IS_LOADING';
export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const ADMIN_CREATE = 'ADMIN_CREATE';
export const ADMIN_FETCH_ONE = 'ADMIN_FETCH_ONE';
export const ADMIN_CLEAR = 'ADMIN_CLEAR';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_LISTS = 'ALERT_LISTS';
export const ALERT_NOTIFICATION_UPDATE = 'ALERT_NOTIFICATION_UPDATE';
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_VERIFY_OTP = 'AUTH_FORGOT_PASSWORD_VERIFY_OTP';
export const AUTH_FORGOT_PASSWORD_CHANGE = 'AUTH_FORGOT_PASSWORD_CHANGE';
export const AUTH_ADD_CONTACT = 'AUTH_ADD_CONTACT';
export const AUTH_ADD_CONTACT_VERIFY = 'AUTH_ADD_CONTACT_VERIFY';
export const AUTH_ADD_CONTACT_UPDATE = 'AUTH_ADD_CONTACT_UPDATE';
export const AUTH_CHANGE_CONTACT_DEFAULT = 'AUTH_CHANGE_CONTACT_DEFAULT';
export const AUTH_CLOST_ACCOUNT = 'AUTH_CLOST_ACCOUNT';
export const AUTH_GENERATE_LINK = 'AUTH_GENERATE_LINK';
export const AUTH_CREATE_ACCOUNT = 'AUTH_CREATE_ACCOUNT';
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const COMPANIES_DATA = 'COMPANIES_DATA';
export const COMPANIES_DETAIL = 'COMPANIES_DETAIL';
export const COMPANIES_UPDATE = 'COMPANIES_UPDATE';
export const COMPANIES_CLEAR = 'COMPANIES_CLEAR';
export const SMS_SEND_MSG = 'SMS_SEND_MSG';
export const SMS_SEND_OTP = 'SMS_SEND_OTP';
export const SMS_VERIFY_OTP = 'SMS_VERIFY_OTP';
export const SMS_CLEAR = 'SMS_CLEAR';
export const EMAIL_SEND_OTP = 'EMAIL_SEND_OTP';
export const EMAIL_VERIFY_OTP = 'EMAIL_VERIFY_OTP';
export const EMAIL_CLEAR = 'EMAIL_CLEAR';
export const FEEDBACK_SEND = 'FEEDBACK_SEND';
export const FEEDBACK_CLEAR = 'FEEDBACK_CLEAR';
export const MASTER_CLEAR = 'MASTER_CLEAR';
export const MASTER_DATA = 'MASTER_DATA';
export const MASTER_DOCTOR_PREFIX = 'MASTER_DOCTOR_PREFIX';
export const MASTER_GROUP_SPECIALTIES = 'MASTER_GROUP_SPECIALTIES';
export const MASTER_TAGS_AND_EVENTS = 'MASTER_TAGS_AND_EVENTS';
export const MASTER_APPS = 'MASTER_APPS';
export const MASTER_MANUALS = 'MASTER_MANUALS';
export const MASTER_FAQS = 'MASTER_FAQS';
export const MASTER_SYMPTOM = 'MASTER_SYMPTOM';
export const MASTER_ORGAN = 'MASTER_ORGAN';
export const MASTER_UPDATE = 'MASTER_UPDATE';
export const MASTER_ICD10 = 'MASTER_ICD10';
export const HOSPITAL_CLEAR = 'HOSPITAL_CLEAR';
export const HOSPITAL_LISTS = 'HOSPITAL_LISTS';
export const HOSPITAL_DATA = 'HOSPITAL_DATA';
export const HOSPITAL_FETCH = 'HOSPITAL_FETCH';
export const HOSPITAL_CREATE = 'HOSPITAL_CREATE';
export const HOSPITAL_UPDATE = 'HOSPITAL_UPDATE';
export const DOCTOR_UPDATE = 'DOCTOR_UPDATE';
export const DOCTOR_CREATE = 'DOCTOR_CREATE';
export const DOCTOR_FETCH = 'DOCTOR_FETCH';
export const DOCTOR_FETCH_ONE = 'DOCTOR_FETCH_ONE';
export const DOCTOR_CLEAR = 'DOCTOR_CLEAR';
export const DOCTOR_WALLET_CLEAR = 'DOCTOR_WALLET_CLEAR';
export const DOCTOR_WALLET_SUMMARY = 'DOCTOR_WALLET_SUMMARY';
export const DOCTOR_WALLET_HISTORY = 'DOCTOR_WALLET_HISTORY';
export const DRUG_FETCH = 'DRUG_FETCH';
export const DRUG_CLEAR = 'DRUG_CLEAR';
export const REFERRAL_FETCH = 'REFERRAL_FETCH';
export const REFERRAL_FETCH_ONE = 'REFERRAL_FETCH_ONE';
export const REFERRAL_LAST_FETCH = 'REFERRAL_LAST_FETCH';
export const REFERRAL_UPDATE = 'REFERRAL_UPDATE';
export const REFERRAL_CLEAR = 'REFERRAL_CLEAR';
export const REALTIME_DATA = 'REALTIME_DATA';
export const CHATS_CLEAR = 'CHATS_CLEAR';
export const CHATS_LIST = 'CHATS_LIST';
export const CHATS_SEND_MESSAGE = 'CHATS_SEND_MESSAGE';
export const PATIENT_FETCH = 'PATIENT_FETCH';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_GET_HEALTH_RECORD = 'PATIENT_GET_HEALTH_RECORD';
export const PATIENT_GET_DOCUMENT = 'PATIENT_GET_DOCUMENT';
export const PATIENT_CLEAR = 'PATIENT_CLEAR';
export const REPORT_CASE_CONSULT = 'REPORT_CASE_CONSULT';
export const REALTIME_SIGNAL = 'REALTIME_SIGNAL';
export const REALTIME_SIGNAL_GUEST = 'REALTIME_SIGNAL_GUEST';
export const WHITELABEL_SETTING = 'WHITELABEL_SETTING';
export const WHITELABEL_SETTING_THEME = 'WHITELABEL_SETTING_THEME';
export const MEDICINE_FETCH_DETAIL = 'MEDICINE_FETCH_DETAIL';
export const MEDICINE_CLEAR = 'MEDICINE_CLEAR';
export const ADMIN_FETCH = 'ADMIN_FETCH';
export const INSURANCE_FETCH = 'INSURANCE_FETCH';
export const INSURANCE_CLEAR = 'INSURANCE_CLEAR';
export const PAYMENT_FETCH = 'PAYMENT_FETCH';
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';
export const PAYMENT_CREATE = 'PAYMENT_CREATE';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';
export const PAYMENT_DATA = 'PAYMENT_DATA';
export const COUPON_FETCH = 'COUPON_FETCH';
export const COUPON_CLEAR = 'COUPON_CLEAR';
export const COUPON_CREATE = 'COUPON_CREATE';
export const COUPON_UPDATE = 'COUPON_UPDATE';
export const COUPON_DATA = 'COUPON_DATA';
export const TOKEN_FETCH = 'TOKEN_FETCH';
export const TOKEN_CLEAR = 'TOKEN_CLEAR';
export const TOKEN_FETCH_ONE = 'TOKEN_FETCH_ONE';
export const MASTER_METHOD_SERVICE = 'MASTER_METHOD_SERVICE';
