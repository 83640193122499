import {
  MEDICINE_FETCH_DETAIL,
  MEDICINE_CLEAR
} from '../actions/types';

const initialState = {
  dropPoint: []
};

export default function MedicineReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case MEDICINE_FETCH_DETAIL:
      return { ...state, ...action.playload };
    case MEDICINE_CLEAR:
      return { ...state, status: undefined };
    default:
      return { ...state };
  }
}
