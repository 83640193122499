import {
  DOCTOR_WALLET_CLEAR,
  DOCTOR_WALLET_SUMMARY,
  DOCTOR_WALLET_HISTORY
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  isAddMore: false,
  nextKey: ''
};

export default function DoctorWalletReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case DOCTOR_WALLET_HISTORY:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case DOCTOR_WALLET_SUMMARY:
      return { ...state, ...action.playload };
    case DOCTOR_WALLET_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
