import {
  PAYMENT_CLEAR,
  PAYMENT_FETCH,
  PAYMENT_CREATE,
  PAYMENT_UPDATE,
  PAYMENT_DATA
} from '../actions/types';

const initialState = {
  data: [],
  isAddMore: false,
  nextKey: ''
};

export default function PaymentReducer(
  state = initialState,
  action = {
    type: undefined
  }
) {
  switch (action.type) {
    case PAYMENT_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore
          ? [...state.data, ...action.playload.data]
          : action?.playload.data
      };
    case PAYMENT_CREATE:
    case PAYMENT_UPDATE:
    case PAYMENT_DATA:
      return { ...state, ...action.playload };
    case PAYMENT_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
