import {
  REALTIME_DATA,
  REALTIME_SIGNAL,
  REALTIME_SIGNAL_GUEST
} from './types';

export const updateRealtime = (name, status, data = {}) => dispatch => {
  dispatch({
    type: REALTIME_DATA,
    playload: {
      name,
      status,
      data
    }
  });
};

export const updateRealtimeSignal = status => dispatch => {
  dispatch({
    type: REALTIME_SIGNAL,
    playload: {
      signalStatus: status
    }
  });
};

export const updateRealtimeSignalGuest = status => dispatch => {
  dispatch({
    type: REALTIME_SIGNAL_GUEST,
    playload: {
      guestAction: status
    }
  });
};

export default updateRealtime;
