import {
  SMS_SEND_MSG,
  SMS_SEND_OTP,
  SMS_VERIFY_OTP,
  SMS_CLEAR
} from '../actions/types';

const initialState = {
  ref: null
};

export default function SmsReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case SMS_SEND_MSG:
    case SMS_SEND_OTP:
    case SMS_VERIFY_OTP:
      return { ...state, ...action.playload };
    case SMS_CLEAR:
      return { ...state, status: undefined };
    default:
      return { ...state };
  }
}
