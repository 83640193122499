import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  DOCTOR_UPDATE,
  DOCTOR_CREATE,
  DOCTOR_FETCH,
  DOCTOR_FETCH_ONE,
  DOCTOR_CLEAR
} from './types';

export const getDoctors = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '', isLoading = true) => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `doctors?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: DOCTOR_CLEAR });
  dispatch({ type: IS_LOADING, status: isLoading, names: { DOCTOR_FETCH: isLoading } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: DOCTOR_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: DOCTOR_FETCH, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const doctorCreate = params => async dispatch => {
  const url = 'doctors';

  dispatch({ type: DOCTOR_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_CREATE: true } });
  await axios.post(url, params).then(response => {
    const resData = response.data;
    const { message } = resData;
    const data = (resData?.data || resData);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: DOCTOR_CREATE, playload: { detail: data, status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_CREATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_CREATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getDoctorByCode = docCode => async dispatch => {
  const url = `doctors?code=${docCode}`;

  dispatch({ type: DOCTOR_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_FETCH_ONE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.docCode === docCode);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: DOCTOR_FETCH_ONE, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_FETCH_ONE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_FETCH_ONE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const doctorUpdate = (docCode, dataSet) => async dispatch => {
  const url = `doctors/${docCode}`;

  dispatch({ type: DOCTOR_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { DOCTOR_UPDATE: true } });
  await axios.put(url, dataSet).then(response => {
    const resData = response.data;
    const { message } = resData;
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: DOCTOR_UPDATE, playload: { status, message } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_UPDATE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: DOCTOR_UPDATE, playload: { status: 'error' } });
    dispatch({ type: IS_LOADING, status: false, names: { DOCTOR_UPDATE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const doctorClear = () => dispatch => {
  dispatch({ type: DOCTOR_CLEAR });
};

export const doctorUpdateEkyc = (docCode, params) => {
  const url = `/doctors/${docCode}`;
  return new Promise(reslove => {
    axios.put(url, params).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove(error);
    });
  });
};
