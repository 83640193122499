import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import _ from 'lodash';
import {
  IS_LOADING,
  TOKEN_FETCH,
  TOKEN_CLEAR,
  TOKEN_FETCH_ONE
} from './types';

export const getTokens = (params = [], limit = 100, options = {
  attrType: 'full',
  columns: []
}, nextKey = '') => async dispatch => {
  let isAddMore = false;
  const newParams = [
    `limit=${limit}`,
    ...params
  ];
  let url = `auth/token?${newParams.join('&')}`;

  if (options?.columns?.length > 0) {
    url += `&attrType=custom&${options.columns.map((column, key) => `attributes[${key}]=${column}`).join('&')}`;
  }
  if (nextKey !== '') {
    isAddMore = true;
    url += `&nextKey=${nextKey}`;
  }

  dispatch({ type: TOKEN_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { TOKEN_FETCH: true } });
  await axios.get(url).then(response => {
    const data = (response.data && response.data.data) || [];
    dispatch({
      type: TOKEN_FETCH,
      playload: {
        data,
        status: 'success',
        isAddMore,
        nextKey: response?.data?.nextKey
      }
    });
    dispatch({ type: IS_LOADING, status: false, names: { TOKEN_FETCH: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: TOKEN_FETCH, playload: { data: [], status: 'success' } });
    dispatch({ type: IS_LOADING, status: false, names: { TOKEN_FETCH: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const getTokenByCode = tokenCode => async dispatch => {
  const url = `auth/token?code=${tokenCode}`;

  dispatch({ type: TOKEN_CLEAR });
  dispatch({ type: IS_LOADING, status: true, names: { TOKEN_FETCH_ONE: true } });
  await axios.get(url).then(response => {
    const resData = response.data;
    const data = ((resData && resData.data) || []);
    const detail = data.find(o => o.authorizationCode === tokenCode);
    let status = 'success';
    if (resData.error || resData.errors) {
      status = 'error';
    }
    dispatch({ type: TOKEN_FETCH_ONE, playload: { detail, status } });
    dispatch({ type: IS_LOADING, status: false, names: { TOKEN_FETCH_ONE: false } });
  }).catch(err => {
    let error = err;
    dispatch({ type: IS_LOADING, status: false, names: { TOKEN_FETCH_ONE: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
  });
};

export const addTokenAccess = async data => {
  const url = '/auth/register';
  return new Promise(reslove => {
    axios.post(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const deleteTokenAccess = async code => {
  const url = `/auth/token/${code}`;
  return new Promise(reslove => {
    axios.delete(url).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const updateTokenAccess = async (code, data) => {
  const url = `/auth/account/${code}`;
  return new Promise(reslove => {
    axios.put(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};

export const loginGetTokenAccess = async data => {
  const url = '/auth/login';
  return new Promise(reslove => {
    axios.post(url, data).then(response => {
      reslove(response.data);
    }).catch(error => {
      reslove({ error });
    });
  });
};
