import {
  COMPANIES_DATA,
  COMPANIES_DETAIL,
  COMPANIES_UPDATE,
  COMPANIES_CLEAR
} from '../actions/types';

const initialState = {
  data: [],
  detail: {},
  nextKey: ''
};

export default function CompaniesReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case COMPANIES_DATA:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore ? [...state.data, ...action.playload.data] : action?.playload.data
      };
    case COMPANIES_DETAIL:
    case COMPANIES_UPDATE:
      return { ...state, ...action?.playload };
    case COMPANIES_CLEAR:
      return {
        ...state,
        detail: {},
        status: undefined,
        message: undefined,
        isAddMore: false,
        nextKey: ''
      };
    default:
      return { ...state };
  }
}
