import {
  INSURANCE_CLEAR,
  INSURANCE_FETCH
} from '../actions/types';

const initialState = {
  data: [],
  nextKey: ''
};

export default function InsuranceReducer(
  state = initialState,
  action = {
    type: undefined
  }
) {
  switch (action.type) {
    case INSURANCE_FETCH:
      return {
        ...state,
        ...action?.playload,
        data: action?.playload?.isAddMore
          ? [...state.data, ...action.playload.data]
          : action?.playload.data
      };
    case INSURANCE_CLEAR:
      return { ...state, status: undefined, message: undefined };
    default:
      return { ...state };
  }
}
