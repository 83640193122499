import {
  CHATS_CLEAR,
  CHATS_LIST,
  CHATS_SEND_MESSAGE
} from '../actions/types';

const initialState = {
  data: {},
  dataAll: [],
  lists: [],
  send: undefined,
  status: undefined
};

export default function ChatsReducer(state = initialState, action = {
  type: undefined
}) {
  switch (action.type) {
    case CHATS_LIST:
    case CHATS_SEND_MESSAGE:
      return { ...state, ...action.playload };
    case CHATS_CLEAR:
      return { ...state, send: undefined, status: undefined };
    default:
      return { ...state };
  }
}
