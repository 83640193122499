import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import moment from 'moment-timezone';
import { Icon } from '@iconify/react';
import _ from 'lodash';
import ringSound from '../assets/sounds/ring.mp3';
import {
  alert,
  getReferralByCode,
  sendNotiByRefCode,
  setListsNotification,
  updateRealtime,
  referralUpdate,
  patientGetHealthRecord
} from '../actions';
import { database, firebase } from '../helpers/firebase';
import Calling from './Modal/Calling';
import CallingWaiting from './Modal/CallingWaiting';
import RequestCallNowNotOnline from './Modal/RequestCallNowNotOnline';
import HowToUse from './Modal/HowToUse';
import SignalDisconnected from './Modal/SignalDisconnected';
import PrepareVideo from './PrepareVideo';
import {
  encrypt,
  getDateTimeDifference,
  isBrowserSupport,
  isAndroid
} from '../helpers/utilities';

let intervalId = null;

const InitialComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [process, setProcess] = useState('load-first');
  const [callingData, setCallingData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [initFirebase, setInitFirebase] = useState(false);
  const [isUseCallingWaiting, setIsUseCallingWaiting] = useState(false);
  const [callingWaitingData, setCallingWaitingData] = useState(null);
  const [dataRequestCallNow, setDataRequestCallNow] = useState();
  const [isOpenRequestCallNow, setIsOpenRequestCallNow] = useState(false);
  const [isOpenSignal, setIsOpenSignal] = useState(false);
  const [isOpenFirstLogin, setOpenFirstLogin] = useState(false);

  const theme = useSelector(state => state.whitelabel.theme);
  const auth = useSelector(selector => selector.auth);
  const { profile } = auth;
  const referral = useSelector(selector => selector.referral);
  const realtime = useSelector(selector => selector.realtime);
  const [dataBookings, setDataBookings] = useState([]);
  const [isOpenPrepareVideo, setOpenPrepareVideo] = useState(false);
  const [dataBooking, setDataBooking] = useState({});
  const [checkInterval, setCheckInterval] = useState(1);
  const [android] = useState(isAndroid());
  const [isBrowser] = useState(isBrowserSupport());

  useEffect(() => {
    if (_.has(isBrowser, 'browser') && (isBrowser.browser === 'line' || isBrowser.browser === 'liff')) {
      const currentURL = window.location.href;
      let urlAndroidRedirect = currentURL;
      const androidUrls = urlAndroidRedirect.split('#');
      urlAndroidRedirect = `${androidUrls[0]}?openExternalBrowser=1#${androidUrls[1]}`;
      const androidUrlsErr = urlAndroidRedirect;
      if (android) {
        try {
          urlAndroidRedirect = urlAndroidRedirect.replace('https://', '//');
          window.location.href = `intent:${urlAndroidRedirect}#Intent;scheme=https;package=com.android.chrome;end`;
        } catch (error) {
          window.location.href = androidUrlsErr;
        }
      } else {
        window.location.href = urlAndroidRedirect;
      }
    }
  }, [isBrowser, android]);

  const signalDisconnectedCallback = () => {
    dispatch(updateRealtime('reconnect-signal', true));
  };

  const toggleRequestCallNow = input => {
    if (input) {
      setDataRequestCallNow(input);
    }
    setIsOpenRequestCallNow(!isOpenRequestCallNow);
  };

  const toggleFirstLogin = () => {
    setOpenFirstLogin(!isOpenFirstLogin);
  };

  const [play, { stop }] = useSound(ringSound, {
    interrupt: true
  });

  const toggle = (val = !isOpen) => {
    setIsOpen(val);
  };

  const toggleWaiting = () => {
    setIsUseCallingWaiting(!isUseCallingWaiting);
  };

  const getRefData = useCallback(data => {
    setProcess('referral-fetch');
    dispatch(getReferralByCode(data.refCode));
  }, [referral]);

  const callingCallbackWaiting = (type, data) => {
    if (type === 'confirm') {
      if (data?.information?.doctor) {
        setDataRequestCallNow(data);
        setIsOpenRequestCallNow(true);
      }
      setIsUseCallingWaiting(false);
      setCallingWaitingData(null);
    } else {
      setIsUseCallingWaiting(false);
      setCallingWaitingData(null);
    }
  };

  const callingCallback = (type, patCode) => {
    if (type === 'accept') {
      dispatch(updateRealtime('calling-accept', true, { patCode }));
      setTimeout(() => {
        dispatch(sendNotiByRefCode(callingData.refCode, 'EVENT_D001'));
      }, 300);
      stop();
      toggle(false);
      const dataSet = {
        refCode: callingData.refCode,
        patCode: callingData.patCode,
        createByApp: callingData.createByApp,
        patientFirstName: callingData.patientFirstName,
        patientLastName: callingData.patientLastName,
        timeSlotRange: callingData.information.timeSlotRange,
        patientMobile: callingData.patientMobile
      };
      const token = encodeURIComponent(encrypt(dataSet).replace(/\//g, '---'));
      setTimeout(() => {
        navigate(`/telemed/${token}`);
      }, 500);
    } else {
      setTimeout(() => {
        dispatch(sendNotiByRefCode(callingData.refCode, 'EVENT_D003'));
      }, 300);
      dispatch(updateRealtime('reject-calling', true, { patCode, refCode: callingData.refCode }));
      stop();
      toggle(false);
    }
  };

  const renderAlert = useCallback(value => {
    const timezone = localStorage.getItem('timezone') || moment.tz.guess();
    const data = {
      title: value?.title,
      message: value?.message.join(', '),
      icon: 'ion:information-circle',
      link: `/consult?data=${value.activity.refCode}`,
      datetime: moment.utc(value.createdAt).tz(timezone),
      fill: false,
      type: 'warning'
    };
    if (value?.status) {
      if (value.status === 'NEW_BOOKING') {
        data.title = t('New appointments');
        data.message = value.message.join(', ');
        data.icon = 'fluent:calendar-checkmark-16-filled';
        data.type = 'success';
        dispatch(updateRealtime('referral-update', true));
      } else if (value.status === 'CHANGE_INFORMATION') {
        data.title = t('Change information appointment');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'ion:information-circle';
        data.type = 'warning';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CONFIRM_CHANGE_BOOKING') {
        data.title = t('Patient confirm change appointment');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'fluent:calendar-checkmark-16-filled';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CHANGE_BOOKING') {
        data.title = t('Patient wants to change the appointment');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'fluent:calendar-arrow-right-16-filled';
        data.type = 'warning';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CANCEL_BOOKING') {
        data.title = t('The consultation appointment was canceled');
        data.message = `#${value.activity.refCode}`;
        data.icon = 'fluent:calendar-arrow-right-16-filled';
        data.type = 'danger';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'CONFIRM_REQUEST_CALL_NOW') {
        data.title = t('Patient accept request call now');
        data.message = `#${value.activity.refCode}`;
        data.type = 'success';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'REJECT_REQUEST_CALL_NOW') {
        data.title = t('Patient reject request call now');
        data.message = `#${value.activity.refCode}`;
        data.type = 'danger';
      } else if (value.status === 'CONFIRM_REQUEST_HEALTH_RECORD') {
        data.title = t('Patient accept request for access to health information');
        data.message = `#${value.activity.refCode}`;
        data.type = 'success';
        dispatch(updateRealtime('referral-update', true, { refCode: value.activity.refCode }));
      } else if (value.status === 'REJECT_REQUEST_HEALTH_RECORD') {
        data.title = t('Patient reject request for access to health information');
        data.message = `#${value.activity.refCode}`;
        data.type = 'danger';
      } else if (value.status === 'CALL_NOW') {
        data.title = t('The patient calls you now');
        data.message = `#${value.activity.refCode}`;
        data.type = 'warning';
      }
    }
    alert(data);
  }, [dispatch, t, updateRealtime]);

  const checkHospital = (phospitals, hosText) => {
    let flagNotify = false;
    phospitals.map(it => {
      if (hosText.search(it) >= 0) {
        flagNotify = true;
      }
      return it;
    });
    return flagNotify;
  };

  useEffect(() => {
    if (profile?.adminCode && !initFirebase) {
      setInitFirebase(true);
      const notifications = firebase.query(firebase.ref(database, 'notifications'), firebase.orderByChild('to_code'), firebase.equalTo(profile.adminCode), firebase.limitToLast(1));
      firebase.onChildAdded(notifications, snapshot => {
        const value = snapshot.val();
        if (value.readBy.includes('NO-READ')) {
          renderAlert(value);
          value.readBy = [profile.docCode];
          value.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
          firebase.update(firebase.ref(database, `notifications/${snapshot.key}`), value);
        }
      });
      const lists = firebase.query(firebase.ref(database, 'notifications'), firebase.orderByChild('to_code'), firebase.equalTo(profile.adminCode), firebase.limitToLast(500));
      const newLists = [];
      firebase.onChildAdded(lists, snapshot => {
        const newValue = snapshot.val();
        if (newValue?.status) {
          newLists.push({
            value: newValue,
            key: snapshot.key
          });
          dispatch(setListsNotification(newLists));
        }
      });
      const activities = firebase.query(firebase.ref(database, 'activities'), firebase.orderByChild('admin'), firebase.equalTo('admin-health-record'), firebase.limitToLast(1));
      firebase.onChildAdded(activities, snapshot => {
        const value = snapshot.val();
        if (value.type === 'AdminHealthRecord' && _.has(value, 'patientName') && _.has(value, 'read') && !value.read && _.has(profile, 'restrictHospitals') && _.isArray(profile.restrictHospitals) && profile.restrictHospitals.length > 0 && _.has(value, 'hospital') && checkHospital(profile.restrictHospitals, value.hospital)) {
          let title = value.type;
          let message = value.title;
          if (_.has(value, 'data.glucose')) {
            title = `${t('Blood sugar')} (${value.patientName})`;
            message = `glucose: ${value.data.glucose}`;
          } else if (_.has(value, 'data.bodyTemp')) {
            title = `${t('Body temperature')} (${value.patientName})`;
            message = `bodyTemp: ${value.data.bodyTemp}`;
          } else if (_.has(value, 'data.dia') && _.has(value, 'data.pul') && _.has(value, 'data.sys')) {
            title = `${t('Blood pressure')}  & ${t('Heart rate')} (${value.patientName})`;
            message = `'dia: ${value.data.dia} ,sys: ${value.data.sys} pul: ${value.data.pul}`;
          } else if (_.has(value, 'data.spo2') && _.has(value, 'data.pr')) {
            title = `${t('Pulse Oximeter')} (${value.patientName})`;
            message = `spo2: ${value.data.spo2} ,pr: ${value.data.pr}`;
          } else if (_.has(value, 'data.height')) {
            title = `${t('Height')} (${value.patientName})`;
            message = `height: ${value.data.height}`;
          } else if (_.has(value, 'data.weight')) {
            title = `${t('Weight')} (${value.patientName})`;
            message = `weight: ${value.data.weight}`;
          } else if (_.has(value, 'data.triggerNo') && _.has(value, 'data.triggerStatus')) {
            title = `${t('SOS')} (${value.patientName})`;
            message = `${t('SOS is activated')}`;
          } else if (_.has(value, 'data.type') && value.data.type === 'heart_rate') {
            title = t('Heart rate');
            message = `pul: ${value.data.value.heart_rate}`;
          } else if (_.has(value, 'data.type') && value.data.type === 'stethoscope') {
            message = t('Stethoscope');
          } else if (_.has(value, 'data.type') && value.data.type === 'otoscope') {
            message = t('Otoscope');
          } else if (_.has(value, 'data.type') && value.data.type === 'ecg') {
            message = t('Electrocardiogram');
          } else if (_.has(value, 'data.type') && value.data.type === 'temp') {
            title = t('Body temperature');
            message = `bodyTemp: ${value.data.value.temp}`;
          } else if (_.has(value, 'data.type') && value.data.type === 'oximeter') {
            title = `${t('Pulse Oximeter')}`;
            message = `spo2: ${value.data.value.oximeter}`;
          }
          value.read = true;
          firebase.update(firebase.ref(database, `activities/${snapshot.key}`), value);
          alert({
            type: 'success',
            title,
            message,
            icon: 'mdi:account-online',
            fill: false,
            link: `/patients/${value.userCode}`
          });
          dispatch(patientGetHealthRecord(value.userCode));
        }
      });
    }
  }, [profile, initFirebase, setInitFirebase]);

  const toVideoRoom = input => {
    const dataSet = {
      refCode: input.refCode,
      patCode: input.patCode,
      createByApp: input.createByApp,
      patientFirstName: input.patientFirstName,
      patientLastName: input.patientLastName,
      timeSlotRange: 15,
      patientMobile: input.patientMobile,
      isCalling: false,
      join: true
    };
    const token = encodeURIComponent(encrypt(dataSet).replace(/\//g, '---'));
    navigate(`/telemed/${token}`);
  };

  useEffect(() => {
    if (realtime.name === 'calling-request' && realtime.status) {
      if (realtime.data && realtime.data.refCode) {
        dispatch(updateRealtime('calling-request', false));
        if (!isOpen) {
          setCallingData();
          getRefData(realtime.data);
        } else if (realtime?.data?.refCode !== callingData?.refCode && realtime?.data?.patCode) {
          dispatch(updateRealtime('calling-busy', true, { refCode: realtime.data.refCode, patCode: realtime.data.patCode }));
        }
      }
    }
    if (realtime.name === 'calling-cancel' && realtime.status) {
      stop();
      toggle(false);
      dispatch(updateRealtime('calling-cancel', false));
    }
    if (realtime.name === 'calling-timeout' && realtime.status) {
      stop();
      toggle(false);
      dispatch(updateRealtime('calling-timeout', false));
    }
    if (realtime.name === 'connect-signal' && !realtime.status && realtime?.data?.refCode) {
      dispatch(updateRealtime('connect-telemed', true));
      const dataEncrypt = { ...realtime.data };
      delete dataEncrypt.referral;
      const token = encodeURIComponent(encrypt(dataEncrypt).replace(/\//g, '---'));
      navigate(`/telemed/${token}`);
    }
    if (realtime.name === 'calling-use-waiting-call' && realtime.status) {
      dispatch(updateRealtime('calling-use-waiting-call', false));
      setCallingWaitingData(realtime.data.referral);
      setIsUseCallingWaiting(true);
    }
    if (realtime.name === 'connect-signal-easycall-send' && realtime.status) {
      dispatch(updateRealtime('connect-signal-easycall-send', false));
      toVideoRoom(realtime.data);
    }
  }, [realtime]);

  useEffect(() => {
    if (referral.status === 'success' && process === 'referral-fetch') {
      setProcess('');
      toggle(true);
      play();
      setCallingData(referral.detail);
    }
  }, [referral, process]);

  useEffect(() => {
    if (realtime.signalStatus === 'disconnected') {
      const currentPath = window.location.pathname;
      if (currentPath.search('/telemed/') < 0) {
        setIsOpenSignal(true);
      }
    }
  }, [realtime]);

  useEffect(() => {
    if (checkInterval && !_.has(dataBooking, 'pendingInformation.visitDate') && _.has(dataBooking, 'refCode') && _.has(dataBooking, 'status') && _.has(dataBooking, 'information.visitDate') && _.has(dataBooking, 'information.visitTime') && (!_.has(dataBooking, 'doctorRecivedCall') || !dataBooking.doctorRecivedCall) && realtime && dataBooking.status === 'paid') {
      let timeSlotRange = 15;
      if (_.has(dataBooking, 'information.timeSlotRange')) {
        timeSlotRange = dataBooking.information.timeSlotRange;
      }
      const timeMilliseconds = timeSlotRange * 60 * 1000;
      const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
      const consultDate = moment(`${dataBooking.information.visitDate} ${dataBooking.information.visitTime}`).format('YYYY-MM-DD HH:mm:ss');
      const differenceInMilliseconds = getDateTimeDifference(currentDate, consultDate);
      if (!isOpenPrepareVideo && differenceInMilliseconds <= 0 && (differenceInMilliseconds + timeMilliseconds) > 0) {
        setOpenPrepareVideo(true);
        clearInterval(intervalId);
      } else if (isOpenPrepareVideo && differenceInMilliseconds <= 0 && (differenceInMilliseconds + timeMilliseconds) < 0) {
        setOpenPrepareVideo(false);
        clearInterval(intervalId);
      }
    }
  }, [dataBooking, checkInterval]);

  useEffect(() => {
    let loop = 1;
    intervalId = setInterval(() => {
      loop += 1;
      setCheckInterval(loop);
    }, 1000 * 60);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (dataBookings.length > 0) {
      const currentDate = moment().format('YYYY-MM-DD');
      const dataCurrent = dataBookings.filter(o => o.information.visitDate === currentDate);
      const data = _.orderBy(dataCurrent, ['information.visitDate', 'information.visitTime'], ['asc', 'asc']);
      if (data.length > 0) {
        setDataBooking(data[0]);
      } else {
        setDataBooking(dataBookings[0]);
      }
    }
  }, [dataBookings]);

  useEffect(() => {
    let dataBookingList = [];
    if (referral.data.length > 0) {
      dataBookingList = referral.data.filter(o => o.status === 'paid' && (_.has(o, 'doctorRecivedCall') || !o.doctorRecivedCall));
    }
    if (referral.lastData.length > 0) {
      dataBookingList = _.concat(dataBookingList, referral.lastData.filter(o => o.status === 'paid' && (_.has(o, 'doctorRecivedCall') || !o.doctorRecivedCall)));
    }
    if (dataBookingList.length > 0) {
      setDataBookings(_.uniqBy(dataBookingList, 'refCode'));
    } else {
      setDataBookings([]);
    }
  }, [referral]);

  const callNow = useCallback(input => {
    const useNewChangeToVideo = true;
    dispatch(referralUpdate(input.refCode, {
      feedback_comment: 'null',
      feedback_rate_start: 'null'
    }));
    if (useNewChangeToVideo) {
      toVideoRoom(input);
    } else {
      setTimeout(() => {
        dispatch(updateRealtime('connect-signal', true, {
          refCode: input.refCode,
          patCode: input.patCode,
          createByApp: input.createByApp,
          patientFirstName: input.patientFirstName,
          patientLastName: input.patientLastName,
          patientImage: input.mapImagePatient,
          timeSlotRange: input.information.timeSlotRange,
          patientMobile: input.patientMobile,
          createByAppName: input.createByAppName,
          useWeb: input.useWeb,
          shortLinkForComplete: input.shortLinkForComplete,
          referral: input,
          callFromDoctor: true
        }));
      }, 300);
    }
  }, [realtime]);

  const callbackPrepareVideo = data => {
    callNow(data);
  };

  const callbackPrepareVideoClose = data => {
    setOpenPrepareVideo(false);
    dispatch(referralUpdate(data.refCode, {
      doctorRecivedCall: true
    }));
  };

  return (
    <>
      {callingData && (
        <Calling isOpen={isOpen} toggle={toggle} data={callingData} callBack={callingCallback} />
      )}
      {(isOpenSignal) && (
        <SignalDisconnected isOpen={isOpenSignal} data={`Realtime is ${realtime.signalStatus}`} callBack={signalDisconnectedCallback} />
      )}
      {isUseCallingWaiting && (
        <CallingWaiting isOpen={isUseCallingWaiting} toggle={toggleWaiting} data={callingWaitingData} callBack={callingCallbackWaiting} />
      )}
      {dataRequestCallNow && dataRequestCallNow.refCode && (
        <RequestCallNowNotOnline isOpen={isOpenRequestCallNow} toggle={toggleRequestCallNow} data={dataRequestCallNow} />
      )}
      {profile?.docCode && (
        <HowToUse docCode={profile?.docCode} isOpen={isOpenFirstLogin} toggle={toggleFirstLogin} />
      )}
      {profile?.docCode && window.location.pathname === '/' && (!_.has(theme, 'companyCode') || theme.companyCode === '' || theme.companyCode === 'null') && (
        <div className="button-fixed-bottom" aria-hidden="true" onClick={toggleFirstLogin.bind(this)}>
          <Icon icon="mdi:youtube" height="32px" className="cursor-pointer" />
        </div>
      )}
      {(profile?.docCode && _.has(dataBooking, 'refCode') && isOpenPrepareVideo && window.location.pathname.search('/telemed/') < 0) && (
        <PrepareVideo data={dataBooking} callback={callbackPrepareVideo} callbackClose={callbackPrepareVideoClose} />
      )}
    </>
  );
};

export default InitialComponent;
